import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  holidays: [],
  addholydayStatus: "idle",
  addholydayError: null,
  status: "idle",
  error: null,
};

export const deleteHoliday = createAsyncThunk(
  "holidays/deleteHoliday",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/holidays/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllHolidays());
        return { data: data, message: "Holiday is successfully deleted" };
      }
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const updateHoliday = createAsyncThunk(
  "holidays/updateHoliday",

  async (body, thunkAPI) => {
    const { id, name, type, startDate, endDate } = body;
    let data;
    try {
      const response = await axios.patch(`/holidays/${id}`, {
        name,
        type,
        startDate,
        endDate,
      });
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllHolidays());
        return {
          data: data,
          message: "Holiday has been updated successfully ! ",
        };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const getAllHolidays = createAsyncThunk(
  "holidays/getAllHolidays",
  async () => {
    let data;
    try {
      const response = await axios.get(`/holidays`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addHoliday = createAsyncThunk(
  "holidays/addHoliday",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/holidays`, body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllHolidays());
        return {
          data: data,
          message: "Holiday has been successfully added",
        };
      }
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

const slice = createSlice({
  name: "holidays",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllHolidays.pending]: (state) => {
      state.status = "loading";
    },
    [getAllHolidays.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.holidays = action.payload;
    },
    [getAllHolidays.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [addHoliday.pending]: (state) => {
      state.addholydayStatus = "loading";
    },
    [addHoliday.fulfilled]: (state, action) => {
      state.addholydayStatus = "succeeded";
    },
    [addHoliday.rejected]: (state, action) => {
      state.addholydayStatus = "failed";
      state.addholydayError = action.error.message;
    },

    [addHoliday.pending]: (state) => {
      state.addholydayStatus = "loading";
    },
    [addHoliday.fulfilled]: (state, action) => {
      state.addholydayStatus = "succeeded";
    },
    [addHoliday.rejected]: (state, action) => {
      state.addholydayStatus = "failed";
      state.addholydayError = action.error.message;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
